// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainNavigation_header__AWWdm {
  max-width: 60rem;
  margin: auto;
  padding: 2px;
  display: flex;
  justify-content: center;
}

.MainNavigation_list__F38-1 {
  display: flex;
  gap: 1rem;
  list-style-type: none;
}

.MainNavigation_list__F38-1 a {
  text-decoration: none;
  color: var(--color-primary-400);
}

.MainNavigation_list__F38-1 a:hover,
.MainNavigation_list__F38-1 a.MainNavigation_active__LZzaI {
  color: var(--color-primary-800);
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainNavigation.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,+BAA+B;AACjC;;AAEA;;EAEE,+BAA+B;EAC/B,0BAA0B;AAC5B","sourcesContent":[".header {\n  max-width: 60rem;\n  margin: auto;\n  padding: 2px;\n  display: flex;\n  justify-content: center;\n}\n\n.list {\n  display: flex;\n  gap: 1rem;\n  list-style-type: none;\n}\n\n.list a {\n  text-decoration: none;\n  color: var(--color-primary-400);\n}\n\n.list a:hover,\n.list a.active {\n  color: var(--color-primary-800);\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `MainNavigation_header__AWWdm`,
	"list": `MainNavigation_list__F38-1`,
	"active": `MainNavigation_active__LZzaI`
};
export default ___CSS_LOADER_EXPORT___;
